<template>
    <div class="PDFViewerBrowser">
        <div class="hearderBox">
            <span @click="backPath">
                <template v-if="isWxWork">返回</template>
                <Svgs v-else name="iconfanhui" color="#000" />
            </span>
        </div>
        <div>
            <iframe :src="iframeUrl" id="h5Frame" class="h5Frame" ref="h5Frame"></iframe>
        </div>
    </div>
</template>

<script>

export default {
    name: "PDFViewerBrowser",
    data() {
        return {
            isWxWork: window.navigator.userAgent.toLowerCase().indexOf('wxwork') !== -1,
            iframeUrl:process.env.VUE_APP_PDFVIEWER+"?file="+encodeURIComponent(this.$route.query.urlPath)
        }
    },
    methods: {
        backPath(){
            this.$router.back();
        }
    }
}
</script>

<style lang="scss">
.PDFViewerBrowser{
    .h5Frame {
    width: 100vw;
    height: 100vh;
  }
}

</style>